
import { Vue, Component } from 'vue-property-decorator';

import $app from '@/plugins/modules';
import { Common } from '@/common';
import { System } from '@/modules/system';
import { Admin } from '@/modules/admin'
import { ITranslateValue, ITranslateItem, ITransFilter, ITransSearch } from '../types';
import { ITableHeader, IPagination } from '../../system/types';

@Component
export default class TranslatesView extends Vue {
  common = $app.module(Common);
  system = $app.module(System);
  admin = $app.module(Admin);

  loading = false;

  filter: ITransFilter = $app.clone(this.admin.$store.TransSearch.filter);

  addItem = false;
  addKey = '';

  showItem = false;
  processItem = false;
  selectedItem: ITranslateItem | null = null;

  get headers(): Array<ITableHeader> {
    return [
      {
        text: $app.i18n('system.Code'),
        value: 'LabelKey',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('system.Language'),
        value: 'LanguageCode',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('admin.system.Translate'),
        value: 'TranslateValue',
        align: 'left',
        width: '80%'
      }
    ];
  }

  get search(): ITransSearch {
    return this.admin.$store.TransSearch;
  }

  async findNext(pagination: IPagination) {
    if (!this.loading) {
      this.loading = true;
      await this.admin.$store.findTranslates(pagination);
      this.loading = false;
    }
  }

  async findFirst() {
    this.loading = false;
    const request = this.system.$module.newSearch(this.admin.$store.TransSearch, this.filter);
    this.admin.$store.setTransSearch({
      items: [],
      filter: request
    });
    this.findNext(request);
  }

  async store(item: ITranslateItem) {
    this.processItem = true;
    try {
      await this.admin.$store.storeTranslates(item);
      this.showItem = false;
      await this.findFirst();
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async remove(item: ITranslateItem) {
    this.processItem = true;
    try {
      await this.admin.$store.removeTranslate(item);
      this.showItem = false;
      await this.findFirst();
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  async open(item: ITranslateValue) {
    try {
      this.selectedItem = await this.admin.$store.loadTranslate(item);
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
  }

  append() {
    this.addKey = '';
    this.addItem = true;
  }

  async doAppend() {
    this.processItem = true;
    try {
      const newItem: ITranslateItem = {
        key: this.addKey,
        values: {
          'en': {
            key: this.addKey,
            lang: 'en',
            value: ''
          }
        }
      };

      this.selectedItem = await this.admin.$store.storeTranslates(newItem);
      this.addItem = false;
      this.showItem = true;
    } catch (err) {
      $app.pushError(err);
    }
    this.processItem = false;
  }

  mounted() {
    if (this.search.filter.totals === 0) {
      this.findFirst();
    }
  }
}
